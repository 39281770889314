export const vantagens = [
  {
    icon: 'wallet',
    title: 'Portabilidade com troco',
    content: 'Refinancie seu contrato atual mantendo o mesmo valor das parcelas e receba um troco na volta.',
  },
  {
    icon: 'card',
    title: 'Cartão Inter Black ',
    content: 'Faça a Portabilidade de Consignado acima de R$ 100 mil para o Inter e receba o Cartão Black sem anuidade, com programa de pontos e vantagens exclusivas!',
  },
  {
    icon: 'agenda',
    title: 'Agilidade e praticidade',
    content: 'Receba o dinheiro em até 3 dias e tenha as parcelas descontadas diretamente do seu benefício ou folha de pagamento.',
  },
  {
    icon: 'smartphones',
    title: 'Atendimento personalizado',
    content: 'Conte com um time de especialistas em Crédito Consignado e receba um atendimento único, de acordo com o que você precisa.',
  },
]
