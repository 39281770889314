import styled from 'styled-components'

export const Wrapper = styled.div`
  
  .open-account-fixed {
    z-index: 10;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'Sora';
  }
`
