import React from 'react'
import Img from 'gatsby-image'
import * as URLs from '../../../../../../config/api/Urls'

// Components
import useWidth from 'src/hooks/window/useWidth'

import { WIDTH_MD, WIDTH_LG, WIDTH_XL } from 'src/utils/breakpoints'

import {
  Section,
  Title,
  Description,
  Link,
} from './style'

import usePageQuery from './../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

export const Hero = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const Width = useWidth()
  const data = usePageQuery()

  const linkHref = `${URLs.API_CONSIGNADO_NOVO}?origem=site&_ga=2.52934760.195875032.1647522679-1126716163.1643727907`

  return (
    <Section className='pt-3 pb-5 py-md-0 d-flex position-relative align-items-center justify-content-between'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <Title>
              Portabilidade de Consignado +<br /> Cartão Inter Black:<br /> Você no seu melhor momento!
            </Title>
            <Description>
              Continue a realização dos seus sonhos com economia ou mais dinheiro no bolso!
              Faça a Portabilidade de Consignado ou um
              Refinanciamento acima de R$ 100 mil e <strong>ganhe o Cartão Inter Black.</strong>
            </Description>
            <Link>
              <a
                href={linkHref}
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    element_action: 'click button',
                    element_name: 'Quero Portabilidade + Cartão Black',
                    section_name: 'Portabilidade de Consignado + Cartão Inter Black: Você no seu melhor momento!',
                    redirect_url: linkHref,
                  })
                }}
              >
                Quero Portabilidade + Cartão Black
              </a>
            </Link>
          </div>
          { Width >= WIDTH_MD &&
            <div className='d-none d-md-block col-md-6 col-xl-5 d-lg-flex align-items-center justify-content-end pb-md-5 pb-xl-0'>
              { Width >= WIDTH_MD && Width < WIDTH_LG
                ? <Img fluid={data.heroBannerPortabilidadeConsignadoMd.fluid} loading='eager' alt='Mulher sorrindo com seu cartão Black' />
              : Width >= WIDTH_LG && Width < WIDTH_XL
                ? <Img fluid={data.heroBannerPortabilidadeConsignadoLg.fluid} loading='eager' alt='Mulher sorrindo com seu cartão Black' />
              : <Img fluid={data.heroBannerPortabilidadeConsignadoXl.fluid} loading='eager' alt='Mulher sorrindo com seu cartão Black' />
              }
            </div>
          }
        </div>
      </div>
    </Section>
  )
}
