export const passos = [
  {
    icon: 'goal',
    title: '1 - Simulação',
    description: 'Você informa pra gente os dados do seu contrato atual, algumas informações pessoais e te mostramos 100% online como funcionará a sua portabilidade de Consignado.',
  },
  {
    icon: 'inter-research',
    title: '2 - Análise e encaminhamento',
    description: 'Vamos analisar com agilidade sua proposta e encaminhar para aprovação da sua instituição atual. Em seguida, vamos te oferecer as melhores condições de acordo com o que você precisa.',
  },
  {
    icon: 'receipt',
    title: '3 - Contratação',
    description: 'Depois disso, é só enviar seus documentos e assinar o contrato de portabilidade de Empréstimo Consignado, que pode ser online.',
  },
  {
    icon: 'credit',
    title: '4 - Efetivação',
    description: 'Pronto! Com tudo certo, você traz seu Crédito Consignado para o Inter e passa a contar com uma das menores taxas de juros do mercado!',
  },
]
