import React from 'react'

import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import {
  Section,
  Title,
  Description,
  Card,
  Head,
  Content,
} from './style'

import { vantagens } from './_vantagens'

type VantagensProps = {
  icon: string;
  title: string;
  content: string;
}

export const VantagensPortabilidade = () => {
  return (
    <Section className='bg-grayscale--100'>
      <div className='container'>
        <div className='row d-md-flex justify-content-center'>
          <div className='col-12 col-md-8 text-md-center'>
            <Title>
              Vantagens da portabilidade de
              crédito consignado do Inter
            </Title>
          </div>
        </div>
        <div className='row d-md-flex justify-content-center'>
          <div className='col-12 col-md-10 col-lg-9 col-xl-8 text-md-center'>
            <Description>
              O Crédito Consignado Inter simplifica sua vida! Você recebe o dinheiro rápido, pode escolher como quer usar o valor e conta ainda com muito mais vantagens:
            </Description>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='row'>
              {vantagens.map((vantagem: VantagensProps, index: number) => (
                <div className='col-12 col-md-6' key={index}>
                  <Card>
                    <Head>
                      <div>
                        <OrangeDsIcon icon={vantagem.icon} size='MD' color='#ff7a00' />
                      </div>
                      <h3>{vantagem.title}</h3>
                    </Head>
                    <Content>
                      {vantagem.content}
                    </Content>
                  </Card>
                </div>
            ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
