import React, { ComponentClass, FunctionComponent, useMemo } from 'react'
import Loadable, { LoadableComponent } from 'react-loadable'
import './MarkdownIcon.scss'

import { LoadingIndicator } from 'inter-site-components'

type MarkdownIconProps = {
  width: string | number;
  height: string | number;
  rotate: string | number;
  icon: string;
  color: string;
  directory: string;
  className?: string;
}

type OrangeIconProps = {
  icon: string;
  color?: string;
  size: string;
  className?: string;
}
interface ISvgIcon {
  width: string | number;
  height: string | number;
  color: string;
  rotate: string | number;
  directory: string;
  className?: string;
}

interface IOrangeIcon {
  color?: string;
  size: string;
  className?: string;
}

function MarkdownIcon ({ width, height, icon, color, rotate, directory, className }: MarkdownIconProps) {
  const SvgIcon: (ComponentClass<ISvgIcon> & LoadableComponent) | (FunctionComponent<ISvgIcon> & LoadableComponent) = useMemo(() => (
    Loadable({
      loader: () => import(`inter-frontend-svgs/lib/${directory}/${icon}`),
      loading: () => <LoadingIndicator size='small' />,
    })
  ), [])
  return (
    <SvgIcon width={width} height={height} color={color} rotate={rotate} directory={directory} className={className} />
  )
}

export function OrangeIcon ({ icon, color, size, className }: OrangeIconProps) {
  const SvgIcon: (ComponentClass<IOrangeIcon> & LoadableComponent) | (FunctionComponent<IOrangeIcon> & LoadableComponent) = useMemo(() => (
    Loadable({
      loader: () => import(`inter-frontend-svgs/lib/orangeds/${size}/${icon}`),
      loading: () => <LoadingIndicator size='small' />,
    })
  ), [])
  return (
    <SvgIcon color={color} size={size} className={className} />
  )
}

MarkdownIcon.defaultProps = {
  width: '',
  height: '',
  icon: '',
  color: 'orange--base',
  rotate: 0,
  directory: 'Icons',
}

export default MarkdownIcon
