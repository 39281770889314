import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  min-height: 500px;
  display: flex;
  align-items: center;
`

export const Title = styled.h3`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${grayscale[500]};

  margin-bottom: 24px;
  margin-right: 3px;

  @media ${device.tablet} {
    margin-bottom: 32px;
    margin-right: 40px;
  }

  @media ${device.desktopLG} {
    font-size: 32px;
    line-height: 40px;
    margin-right: -10px;
  }

  @media ${device.desktopXL} {
    font-size: 40px;
    line-height: 50px;
    margin-right: -35px;
  }
`
