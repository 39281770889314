
import React from 'react'
import { SectionWrapper, Title } from './style'
import Faq from 'src/components/Faq/index'

type Faq = {
  faq: {question: string; answer: string}[];
}

const FAQComponent = (props: Faq) => {
  return (
    <SectionWrapper>
      <div className='container py-5 my-xl-5 px-md-4'>
        <div className='row'>
          <div className='mb-4 col-md-11 col-lg-10 pr-md-5'>
            <Title>
              Perguntas frequentes sobre portabilidade
              de consignado
            </Title>
          </div>
          <Faq
            answerData={props.faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            className='px-0'
          />
        </div>
      </div>
    </SectionWrapper>
  )
}

export default FAQComponent
