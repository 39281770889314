import React from 'react'
import Img from 'gatsby-image'
import * as URLs from '../../../../../../config/api/Urls'

// Hooks
import useWidth from 'src/hooks/window/useWidth'

// Conponents
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeIcon from 'src/components/UI/MarkdownIcon/InterCoIcons'

// PageQuery
import usePageQuery from './../../pageQuery'

// Style
import { ImgSize, Section, CardCarousel, DivCarousel, Link } from './style'

import Exclusividade from '../../assets/data/exclusividade.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type ExclusividadeData = {
  icon: string;
  title: string;
  description: string;
}

const WIDTH_MD = 768

const CuideDaSaude = () => {
  const imagesData = usePageQuery()
  const windowWidth = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  const linkHref = `${URLs.API_CONSIGNADO_NOVO}?origem=site&_ga=2.52934760.195875032.1647522679-1126716163.1643727907`

  return (
    <Section id='cartao-black' className='pb-5 bg-white'>
      <div className='container'>
        <div className='row align-items-center mb-5 pt-md-5'>
          <div className='col-12 col-md-6 offset-md-6 order-md-last pt-md-5 px-0 pl-md-3 px-xl-5'>
            <ImgSize className='d-md-none col-12 col-md-4 col-lg-5 mt-4 mt-md-0 px-0'>
              <Img fluid={imagesData.cartaoBlackInvista.fluid} alt='Cartão Inter Black' />
            </ImgSize>
            <h2 className='fs-24 fs-lg-32 lh-30 lh-lg-40 text-grayscale--500 pr-3'>
              Escolha ser exclusivo. Parcelas reduzidas ou seu troco de volta com <br />Cartão Black
            </h2>
            <p className='fs-14 fs-lg-16 lh-17 lh-lg-19 text-grayscale--400 mt-3 pr-md-3 pr-xl-0'>
              O Inter simplifica, para você poder escolher como <br className='d-none d-lg-block d-xl-none' /> quer realizar os seus
              sonhos com a exclusividade que merece! Ao fazer uma Portabilidade de Consignado
              acima de R$ 100 mil, você pode reduzir o valor das <br className='d-none d-lg-block d-xl-none' /> suas parcelas ou receber um
              troco e levar o <strong><br className='d-none d-md-block d-lg-none' />Cartão Black Inter zero anuidade.</strong>
            </p>
          </div>
        </div>
        <DivCarousel className='row align-items-center'>
          <div className='col-12 col-md-6 col-xl-5 pr-md-0 pl-md-2 pl-lg-3 pb-md-5'>
            <h3 className='fs-24 fs-lg-32 lh-30 lh-lg-40 text-grayscale--500'>Viva essa experiência!</h3>
            <p className='fs-14 fs-lg-16 lh-17 lh-lg-19 text-grayscale--500 mt-4'>Abra sua conta e tenha tudo ao seu alcance com a Portabilidade de Consignado + Cartão Inter Black.</p>
            {windowWidth >= WIDTH_MD &&
              <Link
                onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  element_name: 'Faça a Portabilidade e ganhe Cartão Black!',
                  section_name: 'Escolha ser exclusivo. Parcelas reduzidas ou seu troco de volta com Cartão Black',
                  redirect_url: linkHref,
                  })
                }}
              >
                <a
                  href={linkHref}
                >
                  Faça a Portabilidade e ganhe Cartão Black!
                </a>
              </Link>
            }
          </div>
          <div className='col-12 col-md-6 col-xl-6 offset-xl-1 pt-lg-4'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 100 }}
              md={{ items: 1, partialVisibilityGutter: 100 }}
              lg={{ items: 2, partialVisibilityGutter: 30 }}
              xl={{ items: 2, partialVisibilityGutter: 80 }}
            >
              {
                  Exclusividade.map((item: ExclusividadeData, index: number) => (
                    <CardCarousel
                      key={index}
                      className='bg-grayscale--100 rounded-5 justify-content-between justify-content-md-start d-flex flex-column p-3 mr-4 mr-md-5 mr-lg-4'
                    >
                      <OrangeIcon size='MD' color='#ff7a00' icon={item.icon} />
                      <p className='fs-16 lh-20 text-grayscale--500 fw-600 mt-4' dangerouslySetInnerHTML={{ __html: item.title }} />
                      <p className='fs-14 lh-16 text-grayscale--500' dangerouslySetInnerHTML={{ __html: item.description }} />
                    </CardCarousel>
                  ))
                }
            </DefaultCarousel>
          </div>
        </DivCarousel>
      </div>
    </Section>
  )
}

export default CuideDaSaude
