import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      heroBannerPortabilidadeConsignadoMd: imageSharp(fluid: { originalName: { regex: "/hero-banner-portabilidade-consignado-md/" }}) {
        fluid(maxWidth: 340, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      heroBannerPortabilidadeConsignadoLg: imageSharp(fluid: { originalName: { regex: "/hero-banner-portabilidade-consignado-lg/" }}) {
        fluid(maxWidth: 380, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      heroBannerPortabilidadeConsignadoXl: imageSharp(fluid: { originalName: { regex: "/hero-banner-portabilidade-consignado-xl/" }}) {
        fluid(maxWidth: 505, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      cartaoBlackInvista: imageSharp(fluid: { originalName: { regex: "/cartao-black-portabilidade-consignado-mobile/" }}) {
        fluid(maxWidth: 505, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
