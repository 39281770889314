import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'

export const Section = styled.section`
  position: relative;
  min-height: 633px;
  padding: 40px 0;

  @media ${device.tablet} {
    min-height: 416px;
  }

  @media ${device.desktopLG} {
    min-height: 464px;
    padding: 64px 0;
  }

  @media ${device.desktopXL} {
    min-height: 528px;
    padding: 96px 0;
  }

  .inherit {
   position: unset;
  }
`

export const Title = styled.h2`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${grayscale[500]};
  margin-bottom: 24px;
  margin-right: -2px;

  @media ${device.tablet} {
    padding-top: 104px;
    margin-bottom: 40px;
  }

  @media ${device.desktopLG} {
    padding-top: 67px;
    font-size: 32px;
    line-height: 40px;
  }
  @media ${device.desktopXL} {
    padding-top: 63px;
  }
`

export const Link = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: ${orange.extra};
  padding: 15px 16px;
  border-radius: 8px;
  margin-top: 24px;

  a {
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }
`

export const Card = styled.div`
  background: ${grayscale[100]};
  border: 1px solid ${grayscale[200]};
  border-radius: 16px;
  padding: 24px 19px;
  min-height: 276px;

  @media ${device.tablet} {
    margin-right: 24px;
  }
`

export const Header = styled.p`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${grayscale[500]};
  margin-top: 54px;
  margin-bottom: 8px;
`

export const Body = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${grayscale[500]};
  margin-bottom: 0;
`

export const Caroulsel = styled.div`
  @media ${device.tablet} {
    position: absolute;
    width: 48%;
    right: 0;
    ul {
      padding-top: 0 !important;
    }
  }
`
