import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Section = styled.section`
  min-height: 686px;
  padding: 40px 0 16px;

  @media ${device.tablet} {
    min-height: 341px;
  }

  @media ${device.desktopLG} {
    min-height: 631px;
    padding: 64px 0 40px;
  }

  @media ${device.desktopXL} {
    min-height: 613px;
    padding: 96px 0 72px;
  }
`

export const Title = styled.h2`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${grayscale[500]};

  margin-bottom: 16px;
  margin-right: 3px;

  @media ${device.tablet} {
    margin-bottom: 24px;
  }

  @media ${device.desktopLG} {
    font-size: 32px;
    line-height: 40px;
  }

  @media ${device.desktopXL} {
    font-size: 40px;
    line-height: 50px;
  }
`

export const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[500]};

  margin-bottom: 24px;
  margin-right: -12px;

  @media ${device.tablet} {
    margin-bottom: 40px;
  }

  @media ${device.desktopLG} {
    font-size: 16px;
    line-height: 19px;
  }

  @media ${device.desktopXL} {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 35px;
  }
`

export const Card = styled.div`
  background: #FFFFFF;
  border: 1px solid #DEDFE4;
  border-radius: 16px;
  padding: 24px 22px;
  min-height: 164px;

  margin-bottom: 24px;

  @media ${device.tablet} {
    padding: 24px 20px;
    min-height: 162px;
  }

  @media ${device.desktopLG} {
    min-height: 140px;
  }
`

export const Head = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  margin-bottom: 16px;

  @media ${device.desktopLG} {
    margin-bottom: 31px;
  }

  h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: ${grayscale[500]};

    margin-bottom: 0;
  }
`

export const Content = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${grayscale[500]};

  margin-bottom: 0;

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 17px;
  }

  @media ${device.desktopLG} {
    margin-right: -2px;
  }

  @media ${device.desktopXL} {
    margin-right: -8px;
    font-size: 16px;
    line-height: 19px;
  }
`
