import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import BgSectionMD from './../../assets/images/cartao-black-portabilidade-consignado-mobile-md.png'
import BgSectionLG from './../../assets/images/cartao-black-portabilidade-consignado-mobile-lg.png'
import BgSectionXL from './../../assets/images/cartao-black-portabilidade-consignado-mobile-xl.png'

export const ImgSize = styled.div`  
  .gatsby-image-wrapper {
    width: 350px !important;
    left: -15px;
    margin-bottom: 20px;
  }
`
export const Section = styled.section`
  @media ${device.tablet} {
    background: url(${BgSectionMD}) no-repeat transparent;
    background-position: top left;
  } 
  @media ${device.desktopLG} {
    background: url(${BgSectionLG}) no-repeat transparent;
    background-position: top left;
  } 
  @media ${device.desktopXL} {
    background: url(${BgSectionXL}) no-repeat transparent;
    background-position: 0 0;
    background-size: 1066px;
  }

  .btn {
    @media ${device.tablet} {
      max-width: 320px;
    }
  }

  .react-multiple-carousel__arrow--left {
    left: -8px;
  }
  .react-multiple-carousel__arrow--right {
    right: -10px;
  }
`

export const DivCarousel = styled.div`
  @media ${device.tablet} {
    margin-top: 9rem;
  }
`

export const CardCarousel = styled.div`
  min-height: 212px;
  @media ${device.desktopLG} {
    min-height: 232px;
  }
  @media ${device.desktopXL} {
    min-height: 252px;
  }
`
export const Link = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: ${orange.extra};
  padding: 15px 16px;
  border-radius: 8px;

  a {
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }
  
  @media ${device.tablet} {
    max-width: 96%;
  } 

  @media ${device.desktopLG} {
    max-width: 85%;
  }   
`
