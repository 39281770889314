import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import { Hero } from './sections/Hero/_index'
import { VantagensPortabilidade } from './sections/VantagensPortabilidade/_index'
import { PedirPortabilidade } from './sections/PedirPortabilidade/_index'
import PortabilidadeConsignadoCartaoBlack from './sections/PortabilidadeConsignadaoCartaoBlack/_index'
import FAQ from './sections/Faq/_index'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

const PortabilidadeConsignado = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <PortabilidadeConsignadoCartaoBlack />
        <VantagensPortabilidade />
        <PedirPortabilidade />
        <FAQ faq={pageContext.structuredData.faq} />
      </Layout>
    </Wrapper>
  )
}

export default PortabilidadeConsignado
